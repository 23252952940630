import React from 'react';
import { Container, useMediaQuery, Theme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useUserContext } from 'contexts/RootProvider';
import LoggedInAsUserBar from 'components/common/LoggedInAsUserBar';
import EmailVerifiedAlert from 'components/patient/common/EmailVerifiedAlert';
import ExpiredPasswordScreen from 'components/common/ExpiredPasswordScreen';
import useAuth from 'hooks/common/useAuth';
import { AppThemeProvider } from 'themes/AppTheme';
import Header from './Header';
import { Content, AppLayoutBox } from './AppLayout.styles';
import { useMainMenu } from './useMainMenu';

export default function AppLayout({ children }: React.PropsWithChildren) {
	const upSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
	const contentGridSpacing = upSmallScreen ? 3 : 0;
	const { firstName: accountName = null, balance: accountBalance = null, passwordExpired } = useUserContext();
	const { logout } = useAuth();
	const mainMenu = useMainMenu();

	const handleLogout = () => logout.mutate();

	if (passwordExpired) {
		return (
			<AppThemeProvider>
				<ExpiredPasswordScreen />
			</AppThemeProvider>
		);
	}

	return (
		<AppThemeProvider>
			<EmailVerifiedAlert />
			<AppLayoutBox>
				<LoggedInAsUserBar />
				<Container maxWidth="xl">
					<Header
						mainMenu={mainMenu}
						accountBalance={accountBalance}
						accountName={accountName}
						onLogout={handleLogout}
					/>
					<Content>
						<Grid container spacing={contentGridSpacing}>
							{children}
						</Grid>
					</Content>
				</Container>
			</AppLayoutBox>
		</AppThemeProvider>
	);
}
