import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import ActionsMenu from 'components/common/ActionsMenu';
import { IPatientResponse } from 'core/api/admin/patients.models';
import { PatientStatus } from 'core/models/admin/patients.models';
import LoginIcon from '@mui/icons-material/Login';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import LockResetIcon from '@mui/icons-material/LockReset';
import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';

interface AdminPatientActionsMenuProps {
	patient: IPatientResponse;
	onLoginAs: (patient: IPatientResponse) => void;
	onSuspend: (patient: IPatientResponse) => void;
	onChangePassword: (patientId: number) => void;
	onChangeEmail: (patient: IPatientResponse) => void;
	onChangeDateOfBirth: (patient: IPatientResponse) => void;
	onChangeBypassAuthCode: (patient: IPatientResponse) => void;
	onDelete: (patientId: number) => void;
	isLoginDisabled?: boolean;
}

export function AdminPatientActionsMenu(props: AdminPatientActionsMenuProps) {
	const {
		patient,
		isLoginDisabled,
		onLoginAs,
		onSuspend,
		onChangePassword,
		onChangeEmail,
		onChangeDateOfBirth,
		onChangeBypassAuthCode,
		onDelete,
	} = props;

	return (
		<ActionsMenu>
			<MenuItem onClick={() => onLoginAs(patient)} disabled={isLoginDisabled}>
				<ListItemIcon>
					<LoginIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Log in as</ListItemText>
			</MenuItem>
			<MenuItem
				onClick={() => onSuspend(patient)}
				disabled={patient?.status === PatientStatus.DELETED}
				data-testid="suspend-button"
			>
				<ListItemIcon>
					{patient?.status === PatientStatus.SUSPENDED ? (
						<DoDisturbOffIcon fontSize="small" />
					) : (
						<DoDisturbOnIcon fontSize="small" />
					)}
				</ListItemIcon>
				<ListItemText>{patient?.status === PatientStatus.SUSPENDED ? 'Unsuspend' : 'Suspend'}</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => onChangePassword(patient.userId)}>
				<ListItemIcon>
					<LockResetIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Change Password</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => onChangeEmail(patient)}>
				<ListItemIcon>
					<EditIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Change Email</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => onChangeDateOfBirth(patient)}>
				<ListItemIcon>
					<CalendarMonthIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Change Date of Birth</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => onChangeBypassAuthCode(patient)}>
				<ListItemIcon>
					<KeyIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Change Bypass Auth Code</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => onDelete(patient.userId)} disabled={patient?.status === PatientStatus.DELETED}>
				<ListItemIcon>
					<DeleteIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Delete</ListItemText>
			</MenuItem>
		</ActionsMenu>
	);
}
