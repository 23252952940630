import React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import isActiveRoute from 'utils/isActiveRoute';
import { LoaderFunctionArgs, useLocation, Link as RouterLink, generatePath, useLoaderData } from 'react-router-dom';
import routes from 'routes';
import { adminPatientQueries } from 'queries/admin';
import { QueryClient } from '@tanstack/react-query';
import { AppProvider } from 'contexts/RootProvider';
import { IPatientResponse } from 'core/api/admin/patients.models';
import AdminPatientActions from 'components/admin/AdminPatientActions';
import { useAuth, useDialog } from 'hooks';
import { usePatientDetailsQuery } from 'hooks/admin';

export const loader =
	(queryClient: QueryClient) =>
	async ({ params }: LoaderFunctionArgs) => {
		const { patientId } = params;
		const query = adminPatientQueries.patient({ id: patientId });
		return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
	};

interface AdminPatientLayoutProps {
	children: React.ReactNode;
}

export default function AdminPatientLayout({ children }: AdminPatientLayoutProps) {
	const location = useLocation();
	const initialPatientData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
	const { userId: patientId } = initialPatientData;
	const { data: patient } = usePatientDetailsQuery({ id: patientId.toString() }, initialPatientData);

	const { switchToPatient } = useAuth();
	const { mutate: switchUserMutate, isPending: isPendingSwitchUser } = switchToPatient;

	const [
		isOpenDeleteConfirmationDialog,
		openDeleteConfirmationDialog,
		closeDeleteConfirmationDialog,
		patientIdToDelete,
	] = useDialog<number>();
	const [
		isOpenSuspendConfirmationDialog,
		openSuspendConfirmationDialog,
		closeSuspendConfirmationDialog,
		patientToSuspend,
	] = useDialog<IPatientResponse>();
	const [isOpenChangePasswordDialog, openChangePasswordDialog, closeChangePasswordDialog, patientIdToChangePassword] =
		useDialog<number>();
	const [isOpenChangeEmailDialog, openChangeEmailDialog, closeChangeEmailDialog, patientToChangeEmail] =
		useDialog<IPatientResponse>();
	const [
		isOpenChangeDateOfBirthDialog,
		openChangeDateOfBirthDialog,
		closeChangeDateOfBirthDialog,
		patientToChangeDateOfBirth,
	] = useDialog<IPatientResponse>();
	const [
		isOpenChangeBypassAuthCodeDialog,
		openChangeBypassAuthCodeDialog,
		closeChangeBypassAuthCodeDialog,
		patientToChangeBypassAuthCode,
	] = useDialog<IPatientResponse>();

	const navItems: { to: string; activeRoutes: string[]; label: string }[] = [
		{
			to: generatePath(routes.admin.patients.patient.medSync, {
				patientId,
			}),
			activeRoutes: [routes.admin.patients.patient.medSync],
			label: 'Med Sync',
		},
		{
			to: generatePath(routes.admin.patients.patient.notifications, {
				patientId,
			}),
			activeRoutes: [routes.admin.patients.patient.notifications],
			label: 'Notifications',
		},
		{
			to: generatePath(routes.admin.patients.patient.metaData, {
				patientId,
			}),
			activeRoutes: [routes.admin.patients.patient.metaData],
			label: 'Meta Data',
		},
		{
			to: generatePath(routes.admin.patients.patient.pushDevices, {
				patientId,
			}),
			activeRoutes: [routes.admin.patients.patient.pushDevices],
			label: 'Push Devices',
		},
	];

	return (
		<Grid item container xs={12} spacing={2} alignItems="flex-start" sx={{ marginTop: 0, paddingTop: '0 !important' }}>
			<AppProvider.PageTitle>
				{patient?.fullName ? `Patient ${patient.fullName}` : `Patient #${patientId}`}
			</AppProvider.PageTitle>
			<Grid item xs={12}>
				<Stack direction="row" gap="12px" alignItems="center" justifyContent="space-between">
					<Stack direction="row" gap="12px">
						{navItems.map(({ to, activeRoutes, label }) => (
							<Button
								component={RouterLink}
								to={to}
								key={label}
								variant={isActiveRoute(location.pathname, activeRoutes) ? 'contained' : 'outlined'}
							>
								{label}
							</Button>
						))}
					</Stack>
					<AdminPatientActions.Menu
						patient={patient as IPatientResponse}
						onLoginAs={() => switchUserMutate({ id: patientId.toString() })}
						onSuspend={openSuspendConfirmationDialog}
						onChangePassword={openChangePasswordDialog}
						onChangeEmail={openChangeEmailDialog}
						onChangeDateOfBirth={openChangeDateOfBirthDialog}
						onChangeBypassAuthCode={openChangeBypassAuthCodeDialog}
						onDelete={openDeleteConfirmationDialog}
						isLoginDisabled={isPendingSwitchUser}
					/>
				</Stack>
			</Grid>

			{children}

			<AdminPatientActions.Dialogs
				deleteDialog={{
					isOpen: isOpenDeleteConfirmationDialog,
					patientId: patientIdToDelete,
					onCancel: closeDeleteConfirmationDialog,
				}}
				suspendDialog={{
					isOpen: isOpenSuspendConfirmationDialog,
					patient: patientToSuspend,
					onCancel: closeSuspendConfirmationDialog,
				}}
				passwordDialog={{
					isOpen: isOpenChangePasswordDialog,
					patientId: patientIdToChangePassword?.toString(),
					onClose: closeChangePasswordDialog,
				}}
				emailDialog={{
					isOpen: isOpenChangeEmailDialog,
					patient: patientToChangeEmail,
					onClose: closeChangeEmailDialog,
				}}
				dateOfBirthDialog={{
					isOpen: isOpenChangeDateOfBirthDialog,
					patient: patientToChangeDateOfBirth,
					onClose: closeChangeDateOfBirthDialog,
				}}
				bypassAuthCodeDialog={{
					isOpen: isOpenChangeBypassAuthCodeDialog,
					patient: patientToChangeBypassAuthCode,
					onClose: closeChangeBypassAuthCodeDialog,
				}}
			/>
		</Grid>
	);
}
