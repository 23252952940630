import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAuth from 'hooks/common/useAuth';
import { useIsMutating } from '@tanstack/react-query';
import InactivityDialog from './InactivityDialog';

interface AutoLogoutProps {
	children: React.ReactNode;
}

export function AutoLogout({ children }: AutoLogoutProps) {
	if (!process.env?.REACT_APP_AUTO_LOGOUT_TIME) {
		return children;
	}

	const logoutTime = useMemo(() => Number(process.env.REACT_APP_AUTO_LOGOUT_TIME) * 60 * 1000, []);

	const isMutatingLogout = useIsMutating({ mutationKey: ['logout', 'normal'] });
	const { userToken, logout, programmaticLogout } = useAuth();
	const [showInactivityDialog, setShowInactivityDialog] = useState(false);
	const [isExpired, setIsExpired] = useState(false);

	const updateLastActivity = useCallback(() => {
		localStorage.setItem('lastActivity', Date.now().toString());
	}, []);

	const getLastActivity = useCallback(() => {
		return Number(localStorage.getItem('lastActivity'));
	}, []);

	const clearLastActivity = useCallback(() => {
		localStorage.removeItem('lastActivity');
	}, []);

	const handleResetInactivity = useCallback(() => {
		setShowInactivityDialog(false);
		setIsExpired(false);
		clearLastActivity();
	}, [clearLastActivity]);

	const handleLogin = useCallback(() => {
		handleResetInactivity();
	}, [handleResetInactivity]);

	const handleLogout = useCallback(() => {
		logout.mutate();
	}, [logout]);

	const handleIsExpired = useCallback(
		(state: boolean) => {
			setIsExpired(state);

			if (state && userToken) {
				programmaticLogout.mutate();
			}
		},
		[userToken]
	);

	const checkInactivity = useCallback(() => {
		const lastActivity = getLastActivity();
		if (!lastActivity) {
			handleResetInactivity();
			return;
		}

		const timeSinceLastActivity = Date.now() - lastActivity;
		handleIsExpired(timeSinceLastActivity >= logoutTime);
		setShowInactivityDialog(timeSinceLastActivity >= logoutTime - 1 * 60 * 1000);
	}, [getLastActivity, handleResetInactivity, handleIsExpired, logoutTime]);

	useEffect(() => {
		if (isMutatingLogout) {
			handleResetInactivity();
		}
	}, [isMutatingLogout]);

	// Set up inactivity check interval
	useEffect(() => {
		const interval = setInterval(() => checkInactivity(), 1000);
		return () => clearInterval(interval);
	}, [checkInactivity]);

	// Set up event listeners
	useEffect(() => {
		if (!userToken) {
			return;
		}

		const events = ['keydown', 'click'];

		if (!getLastActivity()) {
			updateLastActivity();
		}

		events.forEach((event) => window.addEventListener(event, updateLastActivity));

		return () => {
			events.forEach((event) => window.removeEventListener(event, updateLastActivity));
		};
	}, [userToken, showInactivityDialog, updateLastActivity, getLastActivity]);

	return (
		<>
			{showInactivityDialog && (
				<InactivityDialog
					isExpired={isExpired}
					lastActivity={getLastActivity()}
					onLogin={handleLogin}
					onLogout={handleLogout}
				/>
			)}
			{!showInactivityDialog && children}
		</>
	);
}

export default AutoLogout;
