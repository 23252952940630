import React from 'react';
import Dialog from 'components/common/dialog';
import ConfirmationDialog from 'components/common/confirmationDialog';
import { PatientStatus } from 'core/models/admin/patients.models';
import { IPatientResponse } from 'core/api/admin/patients.models';
import { usePatientMutations } from 'hooks/admin';

import PatientChangePassword from '../PatientChangePassword';
import PatientChangeEmail from '../PatientChangeEmail';
import PatientChangeDateOfBirth from '../PatientChangeDateOfBirth';
import PatientChangeBypassAuthCode from '../PatientChangeBypassAuthCode';

interface AdminPatientActionsDialogsProps {
	deleteDialog: {
		isOpen: boolean;
		patientId?: number;
		onCancel: () => void;
	};
	suspendDialog: {
		isOpen: boolean;
		patient?: IPatientResponse;
		onCancel: () => void;
	};
	passwordDialog: {
		isOpen: boolean;
		patientId: string;
		onClose: () => void;
	};
	emailDialog: {
		isOpen: boolean;
		patient: IPatientResponse;
		onClose: () => void;
	};
	dateOfBirthDialog: {
		isOpen: boolean;
		patient: IPatientResponse;
		onClose: () => void;
	};
	bypassAuthCodeDialog: {
		isOpen: boolean;
		patient: IPatientResponse;
		onClose: () => void;
	};
}

export function AdminPatientActionsDialogs(props: AdminPatientActionsDialogsProps) {
	const { deleteDialog, suspendDialog, passwordDialog, emailDialog, dateOfBirthDialog, bypassAuthCodeDialog } = props;

	const { suspendPatientMutation, deletePatientMutation } = usePatientMutations();

	const handleDeletePatient = () => {
		if (deleteDialog.patientId) {
			deletePatientMutation.mutate({ userId: deleteDialog.patientId.toString() }, { onSuccess: deleteDialog.onCancel });
		}
	};

	const handleSuspendPatient = () => {
		if (suspendDialog.patient?.userId) {
			suspendPatientMutation.mutate(
				{
					userId: suspendDialog.patient.userId.toString(),
					suspend: suspendDialog.patient.status !== PatientStatus.SUSPENDED,
				},
				{ onSuccess: suspendDialog.onCancel }
			);
		}
	};

	return (
		<>
			<ConfirmationDialog
				isLoading={deletePatientMutation.isPending}
				title="Delete Patient"
				content="Are you sure you want to delete this patient?"
				open={deleteDialog.isOpen}
				onConfirm={handleDeletePatient}
				onCancel={deleteDialog.onCancel}
			/>

			<ConfirmationDialog
				isLoading={suspendPatientMutation.isPending}
				title={suspendDialog.patient?.status === PatientStatus.SUSPENDED ? 'Unsuspend Patient' : 'Suspend Patient'}
				content={
					suspendDialog.patient?.status === PatientStatus.SUSPENDED
						? 'Are you sure you want to unsuspend this patient?'
						: 'Are you sure you want to suspend this patient?'
				}
				open={suspendDialog.isOpen}
				onConfirm={handleSuspendPatient}
				onCancel={suspendDialog.onCancel}
			/>

			<Dialog
				open={passwordDialog.isOpen}
				scroll="paper"
				fullWidth
				maxWidth="xs"
				title="Change Patient's Password"
				onClose={passwordDialog.onClose}
			>
				<PatientChangePassword userId={passwordDialog.patientId} onSuccess={passwordDialog.onClose} />
			</Dialog>

			<Dialog
				open={emailDialog.isOpen}
				scroll="paper"
				fullWidth
				maxWidth="xs"
				title="Change Patient's Email"
				onClose={emailDialog.onClose}
			>
				<PatientChangeEmail patient={emailDialog.patient} onSuccess={emailDialog.onClose} />
			</Dialog>

			<Dialog
				open={dateOfBirthDialog.isOpen}
				scroll="paper"
				fullWidth
				maxWidth="xs"
				title="Change Patient's Date of Birth"
				onClose={dateOfBirthDialog.onClose}
			>
				<PatientChangeDateOfBirth patient={dateOfBirthDialog.patient} onSuccess={dateOfBirthDialog.onClose} />
			</Dialog>

			<Dialog
				open={bypassAuthCodeDialog.isOpen}
				scroll="paper"
				fullWidth
				maxWidth="xs"
				title="Change Bypass Auth Code"
				onClose={bypassAuthCodeDialog.onClose}
			>
				<PatientChangeBypassAuthCode patient={bypassAuthCodeDialog.patient} onSuccess={bypassAuthCodeDialog.onClose} />
			</Dialog>
		</>
	);
}
