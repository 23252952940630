import React, { createContext, useContext, useMemo } from 'react';
import { UserDto } from 'core/api/init/init.models';
import { useQuery } from '@tanstack/react-query';
import initQuery from 'queries/initQuery';

export interface IUserContext extends UserDto {
	isLoading: boolean;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

export const useUserContext = (): IUserContext => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext must be used within a UserProvider');
	}
	return context;
};

interface UserProviderProps {
	children: React.ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
	const { data: initData, isLoading: isLoadingAccount } = useQuery(initQuery());
	const { user } = initData || {};

	const value = useMemo(
		() => ({
			isLoading: isLoadingAccount,
			...user,
		}),
		[isLoadingAccount, user]
	) as IUserContext;

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
